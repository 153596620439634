.logo-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.logo-div img {
  width: 20rem;
}

.form-outer-div {
  margin: 0rem 10rem;
  display: flex;
  justify-content: center;
}

.survey-form {
  max-width: 50rem;
}

.new-section-title.new-section-title.new-section-title {
  margin-top: 5rem;
}

.submit-button {
  margin-bottom: 5rem;
}

@media only screen and (max-width: 700px) {
  .form-outer-div {
    margin: 0rem 2rem;
    max-width: initial;
  }
  
}